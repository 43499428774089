import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Github from "./Github";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/about.png";
import Toolstack from "./Toolstack";
import { Helmet } from "react-helmet";

function About() {
  return (
    <>
      <Helmet>
        <title>About Us</title>

        {/* Google Search Engine Optimization */}
        <meta name="description" content="Hi Everyone, I am Rahul Kumar Singh from Kushinagar, India. I am a final-year student pursuing a B.Tech in Electronics and Communication at NIT Jamshedpur. I am a Full Stack Software Developer with in-depth knowledge of C++, DBMS, React.js, Node.js, Express.js, MongoDB, SQL, and IoT development." />

        <meta name="keywords" content="Rahul Kumar Singh , portfolio , software Engineer , Full Stack Developer , Web Developer , frontend Developer , Backend Developer , Iot Engineer" />

        <meta name="author" content="Rahul Singh" />

        {/* Media Platform.... */}
        <meta property="og:title" content="Rahul Singh - Full Stack Developer" />

        <meta property="og:description" content="Find Out latest Web developement Project || DSA Notes" />

        <meta property="og:image" content="https://rahulsingh.vercel.app/static/media/avatar.e7b5b9659e2771a59b2f.jpg" />

        <meta property="og:url" content="https://rahulsingh.vercel.app" />

      </Helmet>

      <Container fluid className="about-section">
        <Particle />
        <Container>
          <Row style={{ justifyContent: "center", padding: "10px" }}>
            <Col
              md={7}
              style={{
                justifyContent: "center",
                paddingTop: "30px",
                paddingBottom: "50px",
              }}
            >
              <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
                Know Who <strong className="purple">I'M</strong>
              </h1>
              <Aboutcard />
            </Col>
            <Col
              md={5}
              style={{ paddingTop: "120px", paddingBottom: "50px" }}
              className="about-img"
            >
              <img src={laptopImg} alt="about" className="img-fluid" />
            </Col>
          </Row>
          <h1 className="project-heading">
            Professional <strong className="purple">Skillset </strong>
          </h1>

          <Techstack />

          <h1 className="project-heading">
            <strong className="purple">Tools</strong> I use
          </h1>
          <Toolstack />

          <Github />
        </Container>
      </Container>
    </>
  );
}

export default About;
